import MenPage from 'gatsby-theme-husky/src/templates/MenPage/MenPage';
import { graphql } from 'gatsby';
import './MenPageOverride.scss';

export const query = graphql`
  query MenQueryOverride($pageId: String, $lang: String, $relatedProductsValues: [String]) {
    allUmbracoMen(filter: { id: { eq: $pageId } }) {
      nodes {
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        mainCarousel {
          carouselItemsDesktop {
            ...umbracoImageWithAlt
          }
          carouselItemsMobile {
            ...umbracoImageWithAlt
          }
        }
        relatedArticlesTitle
        relatedArticles {
          articleBannerImage {
            ...umbracoImage
          }
          articleBannerImageAltText
          url
          title
          teaser
        }
        relatedProducts {
          relatedProductsTitle
        }
        relatedVideosTitle
        relatedVideosPlayText
        relatedVideos {
          description
          title
          videoImageAlt
          youtubeVideo
          videoCoverImage {
            ...umbracoImage
          }
        }
      }
    }
    tipsAndTricks: allUmbracoTipsAndTricks(filter: { langProps: { lang: { eq: $lang } } }) {
      relatedArticlesButtonNodes: nodes {
        relatedArticlesButton {
          ariaLabel
          text
        }
      }
    }
    relatedProductsList: allSalsifyProducts(
      filter: { sku: { in: $relatedProductsValues }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedProductsNodes: nodes {
        localProductImage {
          childImageSharp {
            fluid(quality: 10) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        relatedProducts {
          relatedProductsLearnMore
          relatedProductsTitle
          buyNow {
            link {
              url
              target
              name
            }
            ariaLabel
            text
            withReverse
          }
        }
        productTitle
        productTitleWithSize
        productTitleShort
        url
      }
    }
  }
`;
export default MenPage;
